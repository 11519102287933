import React from 'react';
import "../../assets/css/phonedropdown.scss";
import { getCountryCallingCode } from 'libphonenumber-js';
import { Dropdown } from "react-bootstrap";

const PhoneDropdown = ({
    value,
    options,
    onChange,
    iconComponent
}) => {
    const Icon = iconComponent;

    return (
        <>
            <Dropdown id="border-custom" className="custom-dropdown dropdown row">
                <Dropdown.Toggle className="mt-0">
                    <Icon label={options.find(country => country.value === value)?.label} country={value} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="main-dropdown-menu">
                    { options.map((country, i) => (
                        <Dropdown.Item
                        key={i}
                        active={value === country.value}
                        onClick={() => onChange(country.value)}
                        className="d-flex"
                        >
                            <Icon
                            title={country.label}
                            country={country.value}
                            label={country.label} />
                            <div className="px-3">
                                {country.label}
                            </div>
                            <div>
                                { country.value && `+${getCountryCallingCode(country.value)}` }
                            </div>  
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            
        </>
    );
}
 
export default PhoneDropdown;