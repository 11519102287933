import React from 'react';

const EmailInput = ({ name, onChange, onBlur, value, placeholder, className, required, readOnly, disabled, setError }) => {
    
    const validateEmail = async (props) => {
        onBlur(props);
        // const url = `https://api.email-validator.net/api/verify?EmailAddress=${value.replace('+', '%2B')}&APIKey=${process.env.EMAIL_API_KEY}`;
        if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
            return;
        }
        
        // const data = await axios.get(url);

        // if (data.data?.status && ![200, 207, 114].includes(data.data.status)) {
        //     setError();
        // }
    }
    
    return (
        <input
          type="email"
          name={name}
          onChange={onChange}
          onBlur={validateEmail}
          value={value}
          placeholder={placeholder}
          className={className}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
        />
    );
}
 
export default EmailInput;