
export default async (email) => {
    
    // const url = `https://api.email-validator.net/api/verify?EmailAddress=${email.replace('+', '%2B')}&APIKey=${process.env.EMAIL_API_KEY}`;
    // if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    //     return false;
    // }
    
    // const data = await axios.get(url);

    // return (!data.data || [200, 207, 114].includes(data.data.status));
    return true;
}